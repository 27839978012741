<template>
  <div class="transaction-payment">
    <h2>PAYMENT INQUIRY (06)</h2>

    <div
      id="canvas"
      style="display:none"
    ></div>
    <dragn-drop @file-change="handleFile"></dragn-drop>

    <div class="card mt-2">
      <div
        class="card-body"
        v-if="textToShow"
      >
        <h3>
          {{ textToShow }}
        </h3>
      </div>
      <div
        class="card-body"
        v-else
      >
        <div class="row">
          <div class="col text-start ">
            <span class="fw-bold">AREEMENT NO. : </span>
            {{ model.agreementNumber }}
          </div>
        </div>
        <div class="row">
          <div class="col text-start">
            <span class="fw-bold">ID CARD NO. : </span> {{ model.idCardNumber }}
          </div>
        </div>
        <div class="row">
          <div class="col text-start">
            <!-- <span class="fw-bold">ชื่อ : </span> {{ model.name }} {{ model.lastName }} -->
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col text-start">
            <span class="fw-bold">วันที่สุดท้าย : </span> {{ model.summaryLatesDate }}
          </div>
        </div>
        <div class="row">
          <div class="col text-start">
            <span class="fw-bold">วันที่สุดท้าย ยอดเงิน: </span>
            {{ numberWithCommas(model.summaryLatesAmount, 2) }}
          </div>
        </div>
        <div class="row">
          <div class="col text-start">
            <span class="fw-bold">ยอดเงินทั้งหมด : </span>
            {{ numberWithCommas(model.summaryTotalAmount, 2) }}
          </div>
        </div>
      </div>
    </div>

    <h3
      class="text-start mt-5"
      v-if="model.format"
    >
      Format {{model.format}}
    </h3>
    <table class="table table-bordered">
      <thead>
        <tr v-if="model.format==1">
          <th>Page</th>
          <th>
            PAYMENT DATE
          </th>
          <th>AMOUNT</th>
          <th>USED</th>
          <th>TYPE</th>
          <th>COUNTER</th>
          <th>STATUS</th>
        </tr>
        <tr v-if="model.format==2">
          <th>Page</th>
          <th>
            PAYMENT DATE
          </th>
          <th>AMOUNT</th>
          <th>H/D CHARGE</th>
          <th>PENALTY</th>
          <th>TYPE</th>
          <th>COUNTER</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in model.rows"
          :key="index"
          :class="[
            {
              'text-danger': row.flagDelete == true,
              'fw-bolder text-success': row.flagFocus == true,
              'bg-gray': row.pageNumber % 2 == 0
            }
          ]"
        >
          <td>{{ row.pageNumber }}</td>
          <td>{{ row.paymentDate }}</td>
          <td>{{ numberWithCommas(row.amount, 2) }}</td>
          <td v-if="model.format == 1">{{ numberWithCommas(row.used, 2) }}</td>
          <td v-if="model.format == 1">{{ row.type }}</td>
          <td v-if="model.format == 1">{{ row.counter }}</td>
          <td v-if="model.format == 1">{{ row.status }}</td>

          <td v-if="model.format == 2">{{ numberWithCommas(row.hdCharge, 2) }}</td>
          <td v-if="model.format == 2">{{ numberWithCommas(row.penalty, 2) }}</td>
          <td v-if="model.format == 2">{{ row.type }}</td>
          <td v-if="model.format == 2">{{ row.counter }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style>
tr.bg-gray {
  background: rgba(0, 0, 0, 0.05);
}
</style>

<script>
import _ from "lodash";

import BaseConvert from "./convert-pdf-to-image.vue";
import DragnDrop from "./dragndrop.vue";

// import _ from 'lodash'

// let agreements = [
//   '5268750600514406',
//   '806971104997',
//   '4090610155203911',
//   '5268750600514406',
//   '8589001100290501',
//   '8588001174464801',
//   '806971104997',
//   '8590001104388415',
//   '8577001551069507',
//   '8588001162191515',
//   '8588001134102616',
// ]

// let ids = [
//   '3120100755040',
//   '1100600125499',
//   '3601000547212',
//   '3120100755040',
//   '1739900048620',
//   '1100600125499',
//   '1100600125499',
//   '1100600125499',
//   '3120600054776',
//   '1343000002273',
//   '3120600916255',
// ]
// agreement number
// min :12
// max 16

// id card number
// min 13
// max 13

export default {
  extends: BaseConvert,
  components: {
    DragnDrop,
  },

  data() {
    return {
      model: {
        summaryLatesDate: "",
        summaryLatesAmount: "",
        summaryTotalAmount: "",
        agreementNumber: "",
        idCardNumber: "",
        name: "",
        lastName: "",
        product: "",
        paymentType: "",
        rows: [],
        format: "",
      },
      textToShow: null,
    };
  },

  methods: {
    clearModel() {
      this.model = {
        summaryLatesDate: "",
        summaryLatesAmount: "",
        summaryTotalAmount: "",
        agreementNumber: "",
        idCardNumber: "",
        name: "",
        lastName: "",
        product: "",
        paymentType: "",
        rows: [],
      };

      this.currentRow = 0;
    },
    numberWithCommas(x, decimal) {
      if (!x) {
        x = 0;
      }

      return x.toFixed(decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clearInputFile() {
      document.getElementById("file").value = null;
    },
    async handleFile(file) {
      if (!file) {
        return;
      }

      this.textToShow = "Loading....";
      this.clearModel();

      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);

      // let url = "https://legal-ocr-function.azurewebsites.net/api/payment";
      // let disabledBaseUrl = true;

      let url = "/payment";
      let disabledBaseUrl = false;

      return this.$http
        .uploadFile(url, formData, {
          disabledBaseUrl,
        })
        .then((res) => {
          this.model = res;
          this.textToShow = "";
        })
        .catch((err) => {
          console.log(err);
          this.textToShow = err;
        })
        .finally(() => {});
    },
  },
};
</script>
